import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";

export default function SubmitButtonLink({ buttonText, newURL }) {
  const matches = useMediaQuery("(min-width:801px)");

  const handleNewURL = () => {
    window.location.href = newURL;
  };

  return (
    <>
      {matches ? (
        <Button
          onClick={handleNewURL}
          size="medium"
          sx={{
            width: "340px",
            marginTop: "8px",
            fontSize: "1.1rem",
            fontWeight: 600,
          }}
          variant="contained"
          style={{ margin: "0 auto", display: "flex" }}
          disableElevation
        >
          {buttonText}
        </Button>
      ) : (
        <Button
          onClick={handleNewURL}
          size="medium"
          sx={{
            width: "100%",
            marginTop: "8px",
            fontSize: "1.1rem",
            fontWeight: 600,
          }}
          variant="contained"
          disableElevation
        >
          {buttonText}
        </Button>
      )}
    </>
  );
}

SubmitButtonLink.propTypes = {
  buttonText: PropTypes.string.isRequired,
  newURL: PropTypes.string.isRequired,
};
