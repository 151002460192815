import React from "react";
import PropTypes from "prop-types";
//---- COMPONENTS ------------------//
//---- 3RD PARTY -------------------//
//---- MUI -------------------------//

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//

//---- EXPORT DEFAULT FUNCTION -----//
export default function BlankTableRow(coloured) {
  //--- PROPS ------------------------//

  var backgroundColor = "#FFFFFF";
  if (coloured) {
    backgroundColor = "#FBF5F9";
  }
  return (
    <tr style={{ backgroundColor: backgroundColor, height: "0.5rem" }}>
      <td colSpan="3"></td>
    </tr>
  );
}
BlankTableRow.propTypes = {
  coloured: PropTypes.bool,
};