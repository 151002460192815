import React from "react";
import PropTypes from "prop-types";

//---- COMPONENTS ------------------//
import {
  telegraphicTransferFee,
  redemptionAdminFee,
  lendersAdminFee,
} from "../../../calculations";
import TotalCostsRow from "./TotalCostsRow";
import TableCaption from "./TableCaption";
import BlankTableRow from "./BlankTableRow";

//---- 3RD PARTY -------------------//
//---- MUI -------------------------//
//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
//---- EXPORT DEFAULT FUNCTION -----//
export default function TotalCostsTable({
  data,
  monthlyInterestRate,
  lenderFacilityFee,
  lenderFacilityFeeRate,
  totalInterestPayable,
  grossLoan,
  ltvBeforeInterest,
  ltvAfterInterest,
  valuationFees,
  legalFees,
  totalMortgageBalance,
  totalPropertyValueSecurity,
  totalMiscCosts,
}) {
  //--- STATES ----------------------//
  //--- CONSTANTS & VARIABLES --------//
  const lenderFacilityFeeRateLabel =
    "Lender Facility Fee (" + lenderFacilityFeeRate * 100 + "%)";

  //--- HANDLERS ---------------------//
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//
  //console.log(ltvBeforeInterest);
  //console.log(ltvAfterInterest);
  return (
    <>
      <table
        style={{
          width: "100%",
          borderRadius: "0.25rem",
          borderCollapse: "collapse",
          fontSize: "1rem",
          lineHeight: "2.5rem",
        }}
      >
        <TableCaption caption={"Total Costs"} />
        <tbody>
  
          <TotalCostsRow
            label={"Monthly Interest Rate"}
            amount={monthlyInterestRate}
            adornment={"%"}
            popoverContent={
              "Monthly rate of interest charged on the loan facility."
            }
          />
          <TotalCostsRow
            label={"Average Monthly Interest"}
            amount={totalInterestPayable / data.loanTerm}
            adornment={"£"}
            popoverContent={
              "This is the average monthly amount of interest charged based on the full term of the loan. Interest is calculated on the loan balance each month and then added to the facility."
            }
          />

          <BlankTableRow />
          <BlankTableRow coloured={true} />
          <TotalCostsRow
            label={"Total Mortgage Balance"}
            amount={totalMortgageBalance}
            adornment={"£"}
            popoverContent={"The total of all mortgages left in place."}
            coloured={true}
          />
          <TotalCostsRow
            label={"Value of Properties Used as Security"}
            amount={totalPropertyValueSecurity}
            adornment={"£"}
            popoverContent={
              "The total value of all properties used as security."
            }
            coloured={true}
          />
          <TotalCostsRow
            label={"Net Loan To Value (LTV)"}
            amount={parseFloat(ltvBeforeInterest.toFixed(1))}
            adornment={"%"}
            popoverContent={
              "This is calculated by taking the gross loan amount, plus any mortgages left in place as a percentage of the total value of the properties being used as security."
            }
            coloured={true}
          />
          <TotalCostsRow
            label={"Gross Loan To Value (LTV)"}
            amount={parseFloat(ltvAfterInterest.toFixed(1))}
            adornment={"%"}
            popoverContent={
              "This is calculated by taking the gross loan amount, plus interest, plus any mortgages left in place as a percentage of the total value of the properties being used as security."
            }
            coloured={true}
          />

          <BlankTableRow coloured={true} />
          <BlankTableRow />
          <TotalCostsRow
            label={"Net Bridging Loan Amount"}
            amount={data.loanAmount}
            adornment={"£"}
            popoverContent={
              "Bridging loan amount required before interest or any other costs have been added."
            }
          />
          <TotalCostsRow
            label={lenderFacilityFeeRateLabel}
            amount={lenderFacilityFee}
            adornment={"£"}
            popoverContent={
              "Calculated as a percentage of the net loan amount and added to the loan facility. Amount of facility fee is illustrated, and the percentage charged is shown in brackets."
            }
          />
          <TotalCostsRow
            label={"Interest if Loan Runs Full Term"}
            amount={totalInterestPayable}
            adornment={"£"}
            popoverContent={
              "This is the total amount of interest that will be charged if the loan is cleared right at the end of its term."
            }
          />
          <TotalCostsRow
            label={"Gross Loan"}
            amount={grossLoan}
            adornment={"£"}
            popoverContent={
              "This is the total of the net loan amount, plus lender facility fee, plus the total roll up interest amount if the loan runs full term."
            }
            bold={500}
          />
          <BlankTableRow />

          <BlankTableRow coloured={true} />

          {/*<TotalCostsRow
          label={"Net Loan Plus Facility Fee"}
          amount={netLoanPlusFacilityFee}
          adornment={"£"}
          popoverContent={
            "This is the net loan amount plus the lender facility fee."
          }
        />*/}

          {/*<TotalCostsRow
          label={"Redemption Amount at Full Term"}
          amount={grossLoan + redemptionAdminFee}
          adornment={"£"}
          popoverContent={
            "This is the estimated amount required to clear the loan facility if it runs full term."
          }
          coloured={true}
        />*/}
          {/*<tr style={{ height: "42px" }}>
          <td colspan="3" style={{ paddingLeft: "0.75rem", textAlign: "left" }}>
            <Typography sx={{ fontWeight: 500 }}>Other Costs</Typography>
          </td>
        </tr>*/}
          <TotalCostsRow
            label={"Valuation Fees"}
            amount={valuationFees}
            adornment={"£"}
            coloured={true}
            popoverContent={
              "This is the estimated cost if a full valuation is required on the properties offered as security. This figure maybe reduced if a desktop, drive by or existing valuation is sufficient for the lender."
            }
          />
          <TotalCostsRow
            label={"Lenders Administration Fee"}
            amount={lendersAdminFee}
            adornment={"£"}
            popoverContent={
              "Most lenders charge administration fees, the amount of which can vary. The fee shown is for a typical plan."
            }
            coloured={true}
          />
          <TotalCostsRow
            label={"Estimated Lender Legal Costs"}
            amount={legalFees}
            adornment={"£"}
            popoverContent={
              "Lenders will require clients to pay any legal fees incurred in relation to arranging their loan."
            }
            coloured={true}
          />
          <TotalCostsRow
            label={"Telegraphic Transfer Fee"}
            amount={telegraphicTransferFee}
            adornment={"£"}
            popoverContent={
              "Lenders are charged this fee for sending the proceeds of the loan to their solicitor. They claim this charge back from their customers."
            }
            coloured={true}
          />
          <TotalCostsRow
            label={"Redemption Administration Fee"}
            amount={redemptionAdminFee}
            adornment={"£"}
            popoverContent={
              "When the loan is repaid the lenders charge an admin fee to remove their charge over the security property."
            }
            coloured={true}
          />
          <TotalCostsRow
            label={"Exit Fee (0%)"}
            amount={0}
            adornment={"£"}
            popoverContent={
              "Some loan plans have exit fees. The vast majority of our loan plans do not."
            }
            coloured={true}
          />
          <TotalCostsRow
            label={"Packager and Broker Fees"}
            amount={0}
            adornment={"£"}
            popoverContent={
              "Chartwell have offered to waive their £500 fee in an exclusive deal for HomeOwners Alliance customers."
            }
            coloured={true}
          />

          <TotalCostsRow
            label={"Total Miscellaneous Costs"}
            amount={totalMiscCosts}
            adornment={"£"}
            coloured={true}
            bold={500}
          />
          <BlankTableRow coloured={true} />
          <BlankTableRow />
          <TotalCostsRow
            label={"Grand Total"}
            amount={grossLoan + totalMiscCosts}
            adornment={"£"}
            bold={700}
            popoverContent={"This is the total cost of the loan facility."}
          />
          <BlankTableRow />
        </tbody>
      </table>
    </>
  );
}
TotalCostsTable.propTypes = {
  data: PropTypes.object.isRequired,
  monthlyInterestRate: PropTypes.number.isRequired,
  lenderFacilityFee: PropTypes.number.isRequired,
  lenderFacilityFeeRate: PropTypes.number.isRequired,
  netLoanPlusFacilityFee: PropTypes.number.isRequired,
  totalInterestPayable: PropTypes.number.isRequired,
  grossLoan: PropTypes.number.isRequired,
  ltvBeforeInterest: PropTypes.number.isRequired,
  ltvAfterInterest: PropTypes.number.isRequired,
  valuationFees: PropTypes.number.isRequired,
  legalFees: PropTypes.number.isRequired,
  totalMortgageBalance: PropTypes.number.isRequired,
  totalPropertyValueSecurity: PropTypes.number.isRequired,
  totalMiscCosts: PropTypes.number.isRequired,
};
