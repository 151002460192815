import React from "react";
import PropTypes from "prop-types";
//---- COMPONENTS ------------------//

//---- 3RD PARTY -------------------//
//---- MUI -------------------------//
import { Typography } from "@mui/material";

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//

//---- EXPORT DEFAULT FUNCTION -----//
export default function TableCaption({caption}) {
  //--- PROPS ------------------------//
  TableCaption.propTypes = {
    caption: PropTypes.string.isRequired,
  };

  //--- STATES ----------------------//
  //--- CONSTANTS & VARIABLES --------//

  //--- HANDLERS ---------------------//
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//

  return (
    <caption
        style={{
          backgroundColor: "#4F145B",
          color: "#FFF",
          paddingLeft: "0.5rem",
          marginBottom: "0 !important",
        }}
      >
        <Typography
          sx={{ lineHeight: "3rem !important", fontSize: "1.1rem !important", fontWeight: "600 !important", color: "#FFF !important", marginBottom: "0 !important" }}
        >
          {caption}
        </Typography>
      </caption>
  );
}
