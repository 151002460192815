export const telegraphicTransferFee = 35;
export const redemptionAdminFee = 40;
export const lenderFacilityFeeRate = 0.02;
export const lendersAdminFee = 145;

// calculate total property value security
export function calculateTotalPropertyValueSecurity(data) {
  const propertyValueSecurity1 = parseInt(data.propertyValueSecurity1, 10);

  let propertyValueSecurity2 = isNaN(data.propertyValueSecurity2)
    ? 0
    : parseInt(data.propertyValueSecurity2, 10);
  let propertyValueSecurity3 = isNaN(data.propertyValueSecurity3)
    ? 0
    : parseInt(data.propertyValueSecurity3, 10);
  let propertyValueSecurity4 = isNaN(data.propertyValueSecurity4)
    ? 0
    : parseInt(data.propertyValueSecurity4, 10);
  let propertyValueSecurity5 = isNaN(data.propertyValueSecurity5)
    ? 0
    : parseInt(data.propertyValueSecurity5, 10);

  const totalPropertyValueSecurity =
    propertyValueSecurity1 +
    propertyValueSecurity2 +
    propertyValueSecurity3 +
    propertyValueSecurity4 +
    propertyValueSecurity5;

  return totalPropertyValueSecurity;
}

// calculate total mortgage balance
export function calculateTotalMortgageBalance(data) {
  const mortgageBalance1 = parseInt(data.mortgageBalance1, 10);

  let mortgageBalance2 = isNaN(data.mortgageBalance2)
    ? 0
    : parseInt(data.mortgageBalance2, 10);
  let mortgageBalance3 = isNaN(data.mortgageBalance3)
    ? 0
    : parseInt(data.mortgageBalance3, 10);
  let mortgageBalance4 = isNaN(data.mortgageBalance4)
    ? 0
    : parseInt(data.mortgageBalance4, 10);
  let mortgageBalance5 = isNaN(data.mortgageBalance5)
    ? 0
    : parseInt(data.mortgageBalance5, 10);

  const totalMortgageBalance =
    mortgageBalance1 +
    mortgageBalance2 +
    mortgageBalance3 +
    mortgageBalance4 +
    mortgageBalance5;

  return totalMortgageBalance;
}

export function calculateLTVBeforeInterest(
  netBridgingLoanAmount,
  totalMortgageBalance,
  totalPropertyValueSecurity
) {
  const ltvBeforeInterest =
    ((netBridgingLoanAmount + totalMortgageBalance) /
      totalPropertyValueSecurity) *
    100;

  return ltvBeforeInterest;
}

export function calculateMonthlyInterestRate(ltvBeforeInterest) {
  let monthlyInterestRate = 0;
  // Monthly Interest Rate
  if (ltvBeforeInterest < 55) {
    monthlyInterestRate = 0.58;
  }
  if (ltvBeforeInterest >= 55 && ltvBeforeInterest < 65) {
    monthlyInterestRate = 0.70;
  }
  if (ltvBeforeInterest >= 65 && ltvBeforeInterest < 70) {
    monthlyInterestRate = 0.70;
  }
  if (ltvBeforeInterest >= 70) {
    monthlyInterestRate = 0.75;
  }

  return monthlyInterestRate;
}

export function calculateLenderFacilityFee(netBridgingLoanAmount) {
  const lenderFacilityFee = parseInt(
    netBridgingLoanAmount * lenderFacilityFeeRate,
    10
  );

  return lenderFacilityFee;
}

export function calculateNetLoanPlusFacilityFee(
  netBridgingLoanAmount,
  lenderFacilityFee
) {
  const netLoanPlusFacilityFee = parseInt(
    netBridgingLoanAmount + lenderFacilityFee,
    10
  );

  return netLoanPlusFacilityFee;
}

export function calculateLTVAfterInterest(
  grossLoanAmount,
  totalMortgageBalance,
  totalPropertyValueSecurity
) {
  const ltvAfterInterest =
    ((grossLoanAmount + totalMortgageBalance) / totalPropertyValueSecurity) *
    100;
  return ltvAfterInterest;
}

export function calculateMonthlyInterestPayable(
  netLoanPlusFacilityFee,
  monthlyInterestRate,
  loanTerm
) {
  //console.log(loanTerm);
  let netLoanPlusFacilityFeeMod = netLoanPlusFacilityFee;
  const monthlyInterestPayable = [];

  for (let i = 0; i < loanTerm; i++) {
    const monthlyInterest =
      netLoanPlusFacilityFeeMod * (monthlyInterestRate / 100);
    const cumulativeLoanAmount = netLoanPlusFacilityFeeMod + monthlyInterest;
    const monthlyInterestPayableObject = {
      monthlyInterest: monthlyInterest,
      cumulativeLoanAmount: cumulativeLoanAmount,
    };
    monthlyInterestPayable.push(monthlyInterestPayableObject);
    netLoanPlusFacilityFeeMod = cumulativeLoanAmount;
  }
  return monthlyInterestPayable;
}

export function calculateGrossLoan(monthlyInterestPayable, loanTerm) {
  //console.log(monthlyInterestPayable);
  //console.log(loanTerm);
  const grossLoan = monthlyInterestPayable[loanTerm - 1].cumulativeLoanAmount;
  return grossLoan;
}

export function calculateTotalInterestPayable(
  grossLoan,
  netLoanPlusFacilityFee
) {
  const totalInterestPayable = grossLoan - netLoanPlusFacilityFee;
  return totalInterestPayable;
}

export function calculateValuationFees(data) {
  const propertyValuationFee1 =
    parseInt(data.propertyValueSecurity1, 10) / 1150;

  let propertyValuationFee2 = isNaN(data.propertyValueSecurity2)
    ? 0
    : parseInt(data.propertyValueSecurity2, 10) / 1150;
  let propertyValuationFee3 = isNaN(data.propertyValueSecurity3)
    ? 0
    : parseInt(data.propertyValueSecurity3, 10) / 1150;
  let propertyValuationFee4 = isNaN(data.propertyValueSecurity4)
    ? 0
    : parseInt(data.propertyValueSecurity4, 10) / 1150;
  let propertyValuationFee5 = isNaN(data.propertyValueSecurity5)
    ? 0
    : parseInt(data.propertyValueSecurity5, 10) / 1150;

  const valuationFees =
    propertyValuationFee1 +
    propertyValuationFee2 +
    propertyValuationFee3 +
    propertyValuationFee4 +
    propertyValuationFee5;

  return valuationFees;
}

export function calculateLegalFees(grossLoan){
    let legalFees = 0;
    if (grossLoan < 250000) {
        legalFees = 720;
    }
    if (grossLoan >= 250000 && grossLoan < 500000) {
        legalFees = 900;
    }
    if (grossLoan >= 500000 && grossLoan < 750000) {
        legalFees = 1200;
    }
    if (grossLoan >= 750000 && grossLoan < 1000000) {
        legalFees = 1400;
    }
    if (grossLoan >= 1000000 && grossLoan < 1500000) {
        legalFees = 1700;
    }
    if (grossLoan >= 1500000) {
        legalFees = 1900;
    }
    return legalFees;
}

export function calculateTotalMiscCosts(valuationFees, lendersAdminFee, legalFees, telegraphicTransferFee, redemptionAdminFee) {
    const totalMiscCosts = 
    valuationFees + 
    lendersAdminFee + 
    legalFees + 
    telegraphicTransferFee +
    redemptionAdminFee;
    return totalMiscCosts;
}