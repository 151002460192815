import React, { useState } from "react";
import { createBrowserHistory } from "history";
import Box from "@mui/material/Box";
//---- MUI -------------------------//

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
//---- 3RD PARTY -------------------//
//---- COMPONENTS ------------------//

import CalculatorInput from "./formParts/CalculatorInput";
import ResultsTable from "./formParts/resultsTable";

export default function SpecialistLending() {
  //--- PROPS ------------------------//
  //--- SETTERS ----------------------//
  const onSubmit = (data) => HandleData(data);
  const [formStep, setFormStep] = useState(0);

  const [data, setData] = useState({});
  const [showField, setShowField] = useState(1);

  //--- CONSTANTS --------------------//
  const customHistory = createBrowserHistory();

  //--- HANDLERS ---------------------//

  function HandleData(data) {
    setData(data);

    //console.log(data);
    customHistory.push({ data });
    setFormStep(1);
    const element = document.getElementById("root");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function HandleEditCalculations() {
    setFormStep(0);
    const element = document.getElementById("root");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function GoBack() {
    window.history.back();
    const element = document.getElementById("root");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  if (formStep === 0) {
    window.onpopstate = GoBack;
  }

  if (formStep === 1) {
    window.onpopstate = HandleEditCalculations;
  }

  //--- CUSTOM HOOKS & USE EFFECT ----//

  //--- JSX --------------------------//

  if (formStep === 0) {
    return (
      <Box sx={{ width: "100%", mb: 2 }}>
        <CalculatorInput
          onSubmit={onSubmit}
          setShowField={setShowField}
          showField={showField}
        />
      </Box>
    );
  } else if (formStep === 1) {
    return (
      <Box sx={{ width: "100%", mb: 2 }}>
        <ResultsTable
          data={data}
          HandleEditCalculations={HandleEditCalculations}
          showField={showField}
        />
      </Box>
    );
  }
}
