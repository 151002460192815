import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";

//---- MUI -------------------------//
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

//---- MUI STYLED COMPONENTS -------------------//
import FormDivider from "../../../components/divider/FormDivider";
import SubmitButton from "../../../components/submit/SubmitButtonNoGrid";
//---- MUI ICONS -------------------//

//---- CUSTOM HOOKS ----------------//

//---- 3RD PARTY -------------------//
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  yupLoanAmount,
  yupPropertyValueSecurity1,
  //yupPropertyValueSecurity2,
  //yupPropertyValueSecurity3,
  //yupPropertyValueSecurity4,
  //yupPropertyValueSecurity5,
  yupMortgageBalance1,
  //yupMortgageBalance2,
  //yupMortgageBalance3,
  //yupMortgageBalance4,
  //yupMortgageBalance5,
} from "../../../config/yupSchema";

//---- COMPONENTS ------------------//
import {
  LoanAmount,
  LoanTerm,
  PropertyValueSecurity1,
  PropertyValueSecurity2,
  PropertyValueSecurity3,
  PropertyValueSecurity4,
  PropertyValueSecurity5,
  MortgageBalance1,
  MortgageBalance2,
  MortgageBalance3,
  MortgageBalance4,
  MortgageBalance5,
} from "../../../formGroups/PropertyDetailsGroup";

//---- EXPORT DEFAULT FUNCTION -----//
export default function CalculatorInput({ onSubmit, showField, setShowField }) {
  //---- FUNCTIONS -------------------//

  //--- SETTERS ----------------------//
  //--- CONSTANTS --------------------//
  const screenWidth = useMediaQuery((theme) => theme.breakpoints.down("md")); // Modify the breakpoint value as needed
  let gridSpacing = screenWidth ? 0 : 6;

  //console.log(showField);
  const schema = yup.object().shape({
    loanAmount: yupLoanAmount,
    propertyValueSecurity1: yupPropertyValueSecurity1,
    mortgageBalance1: yupMortgageBalance1,
    //propertyValueSecurity2: yupPropertyValueSecurity2,
    //mortgageBalance2: yupMortgageBalance2,
    //propertyValueSecurity3: yupPropertyValueSecurity3,
    //mortgageBalance3: yupMortgageBalance3,
    //propertyValueSecurity4: yupPropertyValueSecurity4,
    //mortgageBalance4: yupMortgageBalance4,
    //propertyValueSecurity5: yupPropertyValueSecurity5,
    //mortgageBalance5: yupMortgageBalance5,
  });

  //--- HANDLERS ---------------------//
  //const { handleSubmit, control, reset } = useForm();

  const {
    handleSubmit,
    reset,

    control,

    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur", // "onChange"
  });

  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//

  const handleClick = (event) => {
    event.preventDefault();
    setShowField(showField + 1);
  };

  const handleDeleteProperty = (event) => {
    event.preventDefault();
    setShowField(showField - 1);

    if (showField === 2) {
      reset({
        propertyValueSecurity2: null,
        mortgageBalance2: null,
      });

      localStorage.removeItem("propertyValueSecurity2");
      localStorage.removeItem("mortgageBalance2");
    } else if (showField === 3) {
      reset({
        propertyValueSecurity3: null,
        mortgageBalance3: null,
      });

      localStorage.removeItem("propertyValueSecurity3");
      localStorage.removeItem("mortgageBalance3");
    } else if (showField === 4) {
      reset({
        propertyValueSecurity4: null,
        mortgageBalance4: null,
      });

      localStorage.removeItem("propertyValueSecurity4");
      localStorage.removeItem("mortgageBalance4");
    } else if (showField === 5) {
      reset({
        propertyValueSecurity5: null,
        mortgageBalance5: null,
      });

      localStorage.removeItem("propertyValueSecurity5");
      localStorage.removeItem("mortgageBalance5");
    }
  };

  //console.log(showField);

  // function to add st nd rd th to numbers 1 - 100
  function addOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <>
      <form>

        <Grid container spacing={gridSpacing} sx={{ mb: 1.35 }}>
          <Grid item md={6} xs={12}>
            <FormDivider dividerText={"Loan Requirements"} />
            <LoanAmount control={control} required={true} />
            <LoanTerm control={control} required={true} />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormDivider dividerText={"Properties Used as Security"} />
            <PropertyValueSecurity1 control={control} required={true} />
            <MortgageBalance1 control={control} required={true} />

            {showField > 1 && (
              <>
                <PropertyValueSecurity2 control={control} required={true} />
                <MortgageBalance2 control={control} required={true} />
              </>
            )}
            {showField > 2 && (
              <>
                <PropertyValueSecurity3 control={control} required={true} />
                <MortgageBalance3 control={control} required={true} />
              </>
            )}
            {showField > 3 && (
              <>
                <PropertyValueSecurity4 control={control} required={true} />
                <MortgageBalance4 control={control} required={true} />
              </>
            )}
            {showField > 4 && (
              <>
                <PropertyValueSecurity5 control={control} required={true} />
                <MortgageBalance5 control={control} required={true} />
              </>
            )}

            {showField === 1 && (
              <Box
                mt={0}
                mb={1}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button variant="outlined" onClick={handleClick}>
                  Add a {addOrdinal(showField + 1)} property
                </Button>
              </Box>
            )}

            {(showField === 2 || showField === 3 || showField === 4) && (
              <Box
                mt={0}
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button variant="outlined" onClick={handleDeleteProperty}>
                  Delete {addOrdinal(showField)} property
                </Button>
                <Button variant="outlined" sx={{ ml: 2 }} onClick={handleClick}>
                  Add a {addOrdinal(showField + 1)} property
                </Button>
              </Box>
            )}

            {showField === 5 && (
              <Box mt={0} mb={1}>
                <Button variant="outlined" onClick={handleDeleteProperty}>
                  Delete {addOrdinal(showField)} property
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <SubmitButton
          buttonText={"Calculate bridging loan"}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
        />
     
      </form>
    </>
  );
}

//--- PROPS ------------------------//
CalculatorInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showField: PropTypes.number.isRequired,
  setShowField: PropTypes.func.isRequired,
};
