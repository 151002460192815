import React, { useState } from "react";
import PropTypes from "prop-types";
import StyledPopover from "../../../../../components/popover/StyledPopover";
import IconPopoverLink from "../../../../../components/popover/IconPopoverLink";
import { Typography } from "@mui/material";
import { formatNumber } from "../../../../../utils/miscFunctions";

export default function TotalCostsRow({ label, amount, coloured, adornment, popoverContent, bold }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  var backgroundColor = "#FFFFFF";
  if (coloured) {
    backgroundColor = "#FBF5F9";
  }

  var displayAmount = null;
  if (adornment === "£") {
    displayAmount = formatNumber(amount);
  }
  if (adornment === "%") {
    displayAmount = amount + "%";
  }

  var fontWeight = "normal";
  if (bold === 500) {
    fontWeight = 500;
  }
  if (bold === 700) {
    fontWeight = 700;
  }

  return (
    <>
      <tr style={{ backgroundColor: backgroundColor, height: "40px" }}>
        <td style={{ paddingLeft: "0.75rem", textAlign: "left" }}>
          <Typography sx={{fontWeight : fontWeight}}>{label}</Typography>
        </td>
        <td style={{ paddingRight: "0.25rem", textAlign: "right" }}>
          <Typography sx={{fontWeight : fontWeight}}>{displayAmount}</Typography>
        </td>
        <td style={{ paddingRight: "0.5rem", textAlign: "right"}}>
          {popoverContent && (
            <IconPopoverLink
              handleOpenPopover={handleOpenPopover}
              handleClosePopover={handleClosePopover}
            />
          )}
        </td>
      </tr>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        handleClosePopover={handleClosePopover}
        popoverContent={popoverContent}
      />
    </>
  );
}

TotalCostsRow.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  coloured: PropTypes.bool,
  adornment: PropTypes.string.isRequired,
  popoverContent: PropTypes.string,
  bold: PropTypes.number,
};
