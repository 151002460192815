import React from "react";
import PropTypes from "prop-types";
//---- COMPONENTS ------------------//
//---- 3RD PARTY -------------------//
//---- MUI -------------------------//
import { Typography } from "@mui/material";

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
import { formatNumber } from "../../../../utils/miscFunctions";

//---- EXPORT DEFAULT FUNCTION -----//
export default function ResultsSummary({loanAmount, loanTerm, monthlyInterestRate}) {
  //--- PROPS ------------------------//


  return (
    <>
      <Typography sx={{ pb: "24px", textAlign: "center" }}>
        <span style={{ color: "#D80080", fontWeight: 500, fontSize: "1.3rem" }}>
          {formatNumber(loanAmount)}{" "}
        </span>
        over
        <span style={{ color: "#D80080", fontWeight: 500, fontSize: "1.3rem" }}>
        {" "}{loanTerm}&nbsp;months{" "}
        </span>
        at
        <span style={{ color: "#D80080", fontWeight: 500, fontSize: "1.3rem" }}>
        {" "}{monthlyInterestRate}%
        </span>
      </Typography>
    </>
  );
}

ResultsSummary.propTypes = {
  loanAmount: PropTypes.number.isRequired,
  loanTerm: PropTypes.string.isRequired,
  monthlyInterestRate: PropTypes.number.isRequired,
};
