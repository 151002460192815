/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

import SubmitButtonLink from "../../../../components/submit/SubmitButtonLink";
import ResultsSummary from "./ResultsSummary";
import MonthlyInterestTable from "./MonthlyInterestTable";
import TotalCostsTable from "./totalCostsTable";
import {
  telegraphicTransferFee,
  redemptionAdminFee,
  lendersAdminFee,
  lenderFacilityFeeRate,
  calculateTotalPropertyValueSecurity,
  calculateTotalMortgageBalance,
  calculateLTVBeforeInterest,
  calculateMonthlyInterestRate,
  calculateLenderFacilityFee,
  calculateNetLoanPlusFacilityFee,
  calculateLTVAfterInterest,
  calculateMonthlyInterestPayable,
  calculateGrossLoan,
  calculateTotalInterestPayable,
  calculateValuationFees,
  calculateLegalFees,
  calculateTotalMiscCosts,
} from "../../calculations";
import { Typography } from "@mui/material";

export default function ResultsTable({
  data,
  showField,
  HandleEditCalculations,
}) {
  //console.log(data);

  //--- PROPS ------------------------//
  ResultsTable.propTypes = {
    data: PropTypes.object.isRequired,
    showField: PropTypes.number.isRequired,
    HandleEditCalculations: PropTypes.func.isRequired,
  };

  //console.log("showField", showField);
  //console.log(data);
  if (data) {
    if (showField === 1) {
      //data.propertyValueSecurity2 = 0;
      data.mortgageBalance2 = 0;
      data.propertyValueSecurity3 = 0;
      data.mortgageBalance3 = 0;
      data.propertyValueSecurity4 = 0;
      data.mortgageBalance4 = 0;
      data.propertyValueSecurity5 = 0;
      data.mortgageBalance5 = 0;
    }
    if (showField === 2) {
      data.propertyValueSecurity3 = 0;
      data.mortgageBalance3 = 0;
      data.propertyValueSecurity4 = 0;
      data.mortgageBalance4 = 0;
      data.propertyValueSecurity5 = 0;
      data.mortgageBalance5 = 0;
    }
    if (showField === 3) {
      data.propertyValueSecurity4 = 0;
      data.mortgageBalance4 = 0;
      data.propertyValueSecurity5 = 0;
      data.mortgageBalance5 = 0;
    }
    if (showField === 4) {
      data.propertyValueSecurity5 = 0;
      data.mortgageBalance5 = 0;
    }
    // Default values for null or undefined values.
    if (
      data.propertyValueSecurity2 === null ||
      data.propertyValueSecurity2 === undefined
    )
      data.propertyValueSecurity2 = 0;
    if (data.mortgageBalance2 === null || data.mortgageBalance2 === undefined)
      data.mortgageBalance2 = 0;
    if (
      data.propertyValueSecurity3 === null ||
      data.propertyValueSecurity3 === undefined
    )
      data.propertyValueSecurity3 = 0;
    if (data.mortgageBalance3 === null || data.mortgageBalance3 === undefined)
      data.mortgageBalance3 = 0;
    if (
      data.propertyValueSecurity4 === null ||
      data.propertyValueSecurity4 === undefined
    )
      data.propertyValueSecurity4 = 0;
    if (data.mortgageBalance4 === null || data.mortgageBalance4 === undefined)
      data.mortgageBalance4 = 0;
    if (
      data.propertyValueSecurity5 === null ||
      data.propertyValueSecurity5 === undefined
    )
      data.propertyValueSecurity5 = 0;
    if (data.mortgageBalance5 === null || data.mortgageBalance5 === undefined)
      data.mortgageBalance5 = 0;
  }

  const netBridgingLoanAmount = parseInt(data.loanAmount, 10);
  const loanTerm = parseInt(data.loanTerm, 10);

  const totalPropertyValueSecurity = calculateTotalPropertyValueSecurity(data);
  const totalMortgageBalance = calculateTotalMortgageBalance(data);

  const ltvBeforeInterest = calculateLTVBeforeInterest(
    netBridgingLoanAmount,
    totalMortgageBalance,
    totalPropertyValueSecurity
  );


  const monthlyInterestRate = calculateMonthlyInterestRate(ltvBeforeInterest);
  const lenderFacilityFee = calculateLenderFacilityFee(netBridgingLoanAmount);
  const netLoanPlusFacilityFee = calculateNetLoanPlusFacilityFee(
    netBridgingLoanAmount,
    lenderFacilityFee
  );

  const monthlyInterestPayable = calculateMonthlyInterestPayable(
    netLoanPlusFacilityFee,
    monthlyInterestRate,
    loanTerm
  );

  const grossLoan = calculateGrossLoan(monthlyInterestPayable, loanTerm);

  const totalInterestPayable = calculateTotalInterestPayable(
    grossLoan,
    netLoanPlusFacilityFee
  );

  const ltvAfterInterest = calculateLTVAfterInterest(
    grossLoan,
    totalMortgageBalance,
    totalPropertyValueSecurity
  );

  const valuationFees = calculateValuationFees(data);

  const legalFees = calculateLegalFees(grossLoan);

  const totalMiscCosts = calculateTotalMiscCosts(
    valuationFees,
    lendersAdminFee,
    legalFees,
    telegraphicTransferFee,
    redemptionAdminFee
  );

  const newURL = "https://hoa.org.uk/services/specialist-lending/";
  const phoneNumber = "01454 809 300";
  const phoneNumberLink = "tel:01454809300";
//console.log(ltvBeforeInterest)
//console.log(ltvAfterInterest)
  return (
    <>
      <ResultsSummary
        loanAmount={data.loanAmount}
        loanTerm={data.loanTerm}
        monthlyInterestRate={monthlyInterestRate}
      />

      <div className="bridgingloancalc-container">
        <div className="bridgingloancalc-item">
          <TotalCostsTable
            data={data}
            lenderFacilityFeeRate={lenderFacilityFeeRate}
            lenderFacilityFee={lenderFacilityFee}
            netLoanPlusFacilityFee={netLoanPlusFacilityFee}
            totalInterestPayable={totalInterestPayable}
            grossLoan={grossLoan}
            ltvBeforeInterest={ltvBeforeInterest}
            ltvAfterInterest={ltvAfterInterest}
            monthlyInterestRate={monthlyInterestRate}
            valuationFees={valuationFees}
            legalFees={legalFees}
            totalMortgageBalance={totalMortgageBalance}
            totalPropertyValueSecurity={totalPropertyValueSecurity}
            totalMiscCosts={totalMiscCosts}
          />
        </div>
        <div className="bridgingloancalc-item-margin-top">
          <MonthlyInterestTable
            monthlyInterestPayable={monthlyInterestPayable}
          />
        </div>
      </div>
      <Typography sx={{ mt: 1, mb: 0, fontWeight: 500, textAlign: "Right" }}>
        Rates last updated 17th May 2023
      </Typography>
      <Typography sx={{ mt: 1, mb: 1, textAlign: "Left" }}>
        Our bridging loan calculator should give you a fairly accurate estimate
        of the likely costs of taking out a loan. However, these are not
        guaranteed as interest rates and charges will vary slightly depending on
        the lender.
      </Typography>
      <Button
        variant="outlined"
        onClick={HandleEditCalculations}
        sx={{ mt: 1 }}
      >
        Edit calculation
      </Button>
      <br />
      <div
        className="twobuttons-container"
        style={{ maxWidth: "800px", textAlign: "center", margin: "0 auto" }}
      >
        <div className="twobuttons-column-1">
          <Typography
            variant="h2"
            className="gutenberg-content h2"
            sx={{
              paddingBottom: "1rem !important",
              paddingTop: "1rem !important",
            }}
          >
            Speak to a specialist broker
          </Typography>
          <SubmitButtonLink buttonText={phoneNumber} newURL={phoneNumberLink} />
          <Typography sx={{ textAlign: "left", marginTop: "0.5rem" }}>
            Call from mobile or landline to speak to a specialist broker now
          </Typography>
        </div>
        <div
          className="twobuttons-column-2"
          style={{ fontWeight: 500, marginTop: "48px !important" }}
        ></div>

        <div className="twobuttons-column-3">
          <Typography
            variant="h2"
            className="gutenberg-content h2"
            sx={{
              paddingBottom: "1rem !important",
              paddingTop: "1rem !important",
            }}
          >
            Register your interest
          </Typography>
          <SubmitButtonLink buttonText={"Continue online"} newURL={newURL} />
          <Typography sx={{ textAlign: "left", marginTop: "0.5rem" }}>
            Fill in your details so a specialist broker can get in touch
          </Typography>
        </div>
      </div>
    </>
  );
}

ResultsTable.propTypes = {
  data: PropTypes.object.isRequired,
  showField: PropTypes.number.isRequired,
  HandleEditCalculations: PropTypes.func.isRequired,
};
