import React from "react";
import PropTypes from "prop-types";
//---- COMPONENTS ------------------//

//---- 3RD PARTY -------------------//
//---- MUI -------------------------//
import { Typography } from "@mui/material";

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
import { formatNumber } from "../../../../utils/miscFunctions";
//---- EXPORT DEFAULT FUNCTION -----//
export default function MonthlyInterestTable({ monthlyInterestPayable }) {
  //--- STATES ----------------------//
  //--- CONSTANTS & VARIABLES --------//

  //--- HANDLERS ---------------------//
  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//
  //map the monthlyInterestPayable array to an variable for use in jsx to display a table of monthly interest payable amounts and gross loan amounts
  const table = monthlyInterestPayable.map((monthlyInterestPayable, index) => {
    return (
      <tr
        key={index}
        style={{
          height: "49px",
          backgroundColor: index % 2 === 0 ? "#FFF" : "#FBF5F9",
        }}
      >
        <td style={{ paddingLeft: "1.5rem", textAlign: "center" }}>
          <Typography>{index + 1}</Typography>
        </td>
        <td style={{ textAlign: "center" }}>
          <Typography>
            {formatNumber(monthlyInterestPayable.monthlyInterest)}
          </Typography>
        </td>
        <td style={{ paddingRight: "1.5rem", textAlign: "center" }}>
          <Typography>
            {formatNumber(monthlyInterestPayable.cumulativeLoanAmount)}
          </Typography>
        </td>
      </tr>
    );
  });

  return (
    <table
      style={{
        width: "100%",
        borderRadius: "0.25rem",
        borderCollapse: "collapse",
        fontSize: "1rem",
        lineHeight: "2.5rem",
      }}
    >
      <caption
        style={{
          backgroundColor: "#4F145B",
          color: "#FFF",
          paddingLeft: "0.5rem",
          marginBottom: "0 !important",
        }}
      >
        <Typography
          sx={{ lineHeight: "3rem !important", fontSize: "1.1rem !important", fontWeight: "600 !important", marginBottom: "0 !important", color: "#FFF !important"}}
        >
          Monthly Breakdown
        </Typography>
      </caption>
      <thead
        style={{
          backgroundColor: "#926D99",
          color: "#FFF",
          height: "42px",
          marginBottom: "0 !important",
        }}
      >
        <tr>
          <th
            style={{
              paddingLeft: "1.5rem",
              width: "20%",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500, marginBottom: "0 !important", color: "#FFF !important",}}>Month</Typography>
          </th>
          <th
            style={{
              textAlign: "center",
              width: "50%",
            }}
          >
            <Typography sx={{ fontWeight: 500, marginBottom: "0 !important", color: "#FFF !important", }}>Interest</Typography>
          </th>
          <th
            style={{
              paddingRight: "1.5rem",
              width: "40%",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500, marginBottom: "0 !important", color: "#FFF !important", }}>Gross Loan</Typography>
          </th>
        </tr>
      </thead>
      <tbody>{table}</tbody>
    </table>
  );
}
//--- PROPS ------------------------//
MonthlyInterestTable.propTypes = {
  monthlyInterestPayable: PropTypes.array.isRequired,
};
