import React, { useState } from "react";
import PropTypes from "prop-types";
import TextFieldElement from "../components/textfield/TextFieldElement";
import SelectElement from "../components/select/SelectElement";

const buyProperty = localStorage.getItem("buyProperty");

function getFieldValues(fieldName, storedArray) {
  if (storedArray !== null) {
    const initialValue = JSON.parse(storedArray);
    return initialValue[fieldName];
  } else {
    return "";
  }
}

export const AddressLine1 = ({ control, required }) => {
  const [addressLine1] = useState(() => {
    return getFieldValues("addressLine1", buyProperty);
  });

  return (
    <TextFieldElement
      defaultValue={addressLine1}
      name={"addressLine1"}
      control={control}
      label={"Address Line 1"}
      required={required}
      pattern={null}
      placeholder={'Enter the first line of your address'}
    />
  );
};

AddressLine1.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const AddressLine2 = ({ control, required }) => {
  const [addressLine2] = useState(() => {
    return getFieldValues("addressLine2", buyProperty);
  });

  return (
    <TextFieldElement
      defaultValue={addressLine2}
      name={"adressLine2"}
      control={control}
      label={"Address Line 2"}
      required={required}
      pattern={null}
    />
  );
};

AddressLine2.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const Postcode = ({ control, required, register, getValues, label = "Postcode" }) => {
  // You might need to handle getFieldValues and buyProperty here
  //const [storedPostcode] = useState(() => {
  //  return getFieldValues("postcode", buyProperty);
  //});

  const sessionPostcode = (sessionStorage.getItem('postcode') || "").toUpperCase();

  return (
    <TextFieldElement
      autoComplete={"postal-code"}
      getValues={getValues}
      register={register}
      defaultValue={sessionPostcode} // Using sessionPostcode here
      name={"postcode"}
      control={control}
      label={label}
      placeholder={"e.g. SW1A 1AA"}
      required={required}
      pattern={/^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i}
      limitToAlphaNumericAndCapitalise={true}
    />
  );
};

Postcode.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  register: PropTypes.func,
  getValues: PropTypes.object,
  label: PropTypes.string,
};

export const Outcode = ({ control, required, register, getValues, label = "Postcode" }) => {


  const sessionOutcode = (sessionStorage.getItem('postcode') || "").toUpperCase();

  return (
    <TextFieldElement
      autoComplete={"postal-code"}
      getValues={getValues}
      register={register}
      defaultValue={sessionOutcode} // Using sessionPostcode here
      name={"postcode"}
      control={control}
      label={label}
      placeholder={"e.g. SW19 or SW19 1AA"}
      required={required}
      pattern={/^[A-Z]{1,2}[0-9R][0-9A-Z]?$/i}
      limitToAlphaNumericAndCapitalise={true}
    />
  );
};

Outcode.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  register: PropTypes.func,
  getValues: PropTypes.object,
  label: PropTypes.string,
};

export const DevelopmentName = ({ control, required }) => {
  const [developmentName] = useState(() => {
    return getFieldValues("developmentName", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={developmentName}
      name={"developmentName"}
      control={control}
      label={"Development Name"}
      required={required}
      pattern={null}
      placeholder={'Enter the name of the development'}
    />
  );
};

DevelopmentName.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const Information = ({ control, required, rows, label = "Provide any relevant information" }) => {
  return (
    <TextFieldElement
      name={"information"}
      control={control}
      label={label}
      required={required}
      pattern={null}
      rows={rows}
      popoverContent={"Information you provide here will be passed to the supplier"}
    />
  );
};

Information.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  rows: PropTypes.number,
  label: PropTypes.string,
};

export const PropertySize = ({ control, required }) => {
  const [propertySize] = useState(() => {
    return getFieldValues("propertySize", buyProperty);
  });

  const options = [
    {
      value: "1",
      label: "1 bedroom",
    },
    {
      value: "2",
      label: "2 bedrooms",
    },
    {
      value: "3",
      label: "3 bedrooms",
    },
    {
      value: "4",
      label: "4 bedrooms",
    },
    {
      value: "5",
      label: "5 bedrooms",
    },
    {
      value: "6",
      label: "More than 5 bedrooms",
    },
  ];

  return (
    <SelectElement
      defaultValue={propertySize}
      name={"propertySize"}
      control={control}
      label={"Property Size"}
      required={required}
      options={options}
      placeholder={"- Select bedrooms -"}
    />
  );
};

PropertySize.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const PropertyValueAny = ({ control, required }) => {
  const [propertyValueAny] = useState(() => {
    return getFieldValues("propertyValueAny", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={propertyValueAny}
      name={"propertyValueAny"}
      control={control}
      label={"Property Value"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
      formatThousands={false}
    />
  );
};

PropertyValueAny.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const PropertyValue = ({ control, required }) => {
  const [propertyValue] = useState(() => {
    return getFieldValues("propertyValue", buyProperty);
  });

  const options = [
    {
      value: "100000",
      label: "£0 - £100,000",
    },
    {
      value: "200000",
      label: "£100,001 - £200,000",
    },
    {
      value: "300000",
      label: "£200,001 - £300,000",
    },
    {
      value: "400000",
      label: "£300,001 - £400,000",
    },
    {
      value: "500000",
      label: "£400,001 - £500,000",
    },
    {
      value: "600000",
      label: "£500,001 - £600,000",
    },
    {
      value: "700000",
      label: "£600,001 - £700,000",
    },
    {
      value: "800000",
      label: "£700,001 - £800,000",
    },
    {
      value: "900000",
      label: "£800,001 - £900,000",
    },
    {
      value: "1000000",
      label: "£900,001 - £1,000,000",
    },
    {
      value: "2000000",
      label: "£1,000,001 - £2,000,000",
    },
    {
      value: "5000000",
      label: "£2,000,001 - £5,000,000",
    },
    {
      value: "9999999",
      label: "£5,000,001 and above",
    },
  ];

  return (
    <SelectElement
      defaultValue={propertyValue}
      name={"propertyValue"}
      control={control}
      label={"Property Value"}
      required={required}
      options={options}
      placeholder={"- Select property value -"}
    />
  );
};

PropertyValue.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const PropertyType = ({ control, required }) => {
  const [propertyType] = useState(() => {
    return getFieldValues("propertyType", buyProperty);
  });

  const options = [
    {
      value: "flat",
      label: "Flat",
    },
    {
      value: "terraced",
      label: "Terraced",
    },
    {
      value: "semi",
      label: "Semi",
    },
    {
      value: "detached",
      label: "Detached",
    },
  ];

  return (
    <SelectElement
      defaultValue={propertyType}
      name={"propertyType"}
      control={control}
      label={"Property Type"}
      required={required}
      options={options}
      placeholder={"- Select type of property -"}
    />
  );
};

PropertyType.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const AppointWhen = ({ control, required }) => {
  const [appointWhen] = useState(() => {
    return getFieldValues("appointWhen", buyProperty);
  });

  const options = [
    {
      value: "Now",
      label: "Now",
    },
    {
      value: "3 - 6 months",
      label: "3 - 6 months",
    },
    {
      value: "I have an agent",
      label: "I have an agent",
    },
    {
      value: "Not looking to sell",
      label: "Not looking to sell",
    },
  ];

  return (
    <SelectElement
      defaultValue={appointWhen}
      name={"appointWhen"}
      control={control}
      label={"Appoint When"}
      required={required}
      options={options}
      placeholder={"- Select your timescale -"}
    />
  );
};

AppointWhen.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};



export const AppointWhenEA4Me = ({ control, required }) => {
  const [appointWhen] = useState(() => {
    return getFieldValues("appointWhen", buyProperty);
  });

  const options = [
    {
      value: "1",
      label: "Now",
    },
    {
      value: "2",
      label: "3 - 6 months",
    },
    {
      value: "3",
      label: "I have an agent",
    },
    {
      value: "4",
      label: "Not looking to sell",
    },
  ];

  return (
    <SelectElement
      defaultValue={appointWhen}
      name={"appointWhen"}
      control={control}
      label={"Appoint When"}
      required={required}
      options={options}
      placeholder={"- Select your timescale -"}
    />
  );
};

AppointWhenEA4Me.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};



export const LoanAmount = ({ control, required }) => {
  const [loanAmount] = useState(() => {
    return getFieldValues("loanAmount", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={loanAmount}
      name={"loanAmount"}
      control={control}
      label={"Amount Required"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

LoanAmount.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const LoanTerm = ({ control, required }) => {
  const options = [
    { value: "1", label: "1 month" },
    { value: "2", label: "2 months" },
    { value: "3", label: "3 months" },
    { value: "4", label: "4 months" },
    { value: "5", label: "5 months" },
    { value: "6", label: "6 months" },
    { value: "7", label: "7 months" },
    { value: "8", label: "8 months" },
    { value: "9", label: "9 months" },
    { value: "10", label: "10 months" },
    { value: "11", label: "11 months" },
    { value: "12", label: "12 months" },
    { value: "13", label: "13 months" },
    { value: "14", label: "14 months" },
    { value: "15", label: "15 months" },
    { value: "16", label: "16 months" },
    { value: "17", label: "17 months" },
    { value: "18", label: "18 months" },
    { value: "19", label: "19 months" },
    { value: "20", label: "20 months" },
    { value: "21", label: "21 months" },
    { value: "22", label: "22 months" },
    { value: "23", label: "23 months" },
    { value: "24", label: "24 months" },
  ];

  return (
    <SelectElement
      defaultValue={"12"}
      name={"loanTerm"}
      control={control}
      label={"Term Required?"}
      required={required}
      options={options}
      popoverContent={"The maximum number of months that you require the loan for."}
    />
  );
};

LoanTerm.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const PropertyValueSecurity1 = ({ control, required }) => {
  const [propertyValueSecurity1] = useState(() => {
    return getFieldValues("propertyValue", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={propertyValueSecurity1}
      name={"propertyValueSecurity1"}
      control={control}
      label={"Property Value"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      popoverContent={"The estimated market value of the property being used as security."}
      startAdornment={"£"}
    />
  );
};

PropertyValueSecurity1.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const PropertyValueSecurity2 = ({ control, required }) => {
  const [propertyValueSecurity2] = useState(() => {
    return getFieldValues("propertyValue", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={propertyValueSecurity2}
      name={"propertyValueSecurity2"}
      control={control}
      label={"2nd Property Value"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

PropertyValueSecurity2.propTypes = {
  control: PropTypes.object,
  required: PropTypes.bool,
};

export const PropertyValueSecurity3 = ({ control, required }) => {
  const [propertyValueSecurity3] = useState(() => {
    return getFieldValues("propertyValue", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={propertyValueSecurity3}
      name={"propertyValueSecurity3"}
      control={control}
      label={"3rd Property Value"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

PropertyValueSecurity3.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const PropertyValueSecurity4 = ({ control, required }) => {
  const [propertyValueSecurity4] = useState(() => {
    return getFieldValues("propertyValue", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={propertyValueSecurity4}
      name={"propertyValueSecurity4"}
      control={control}
      label={"4th Property Value"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

PropertyValueSecurity4.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const PropertyValueSecurity5 = ({ control, required }) => {
  const [propertyValueSecurity5] = useState(() => {
    return getFieldValues("propertyValue", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={propertyValueSecurity5}
      name={"propertyValueSecurity5"}
      control={control}
      label={"5th Property Value"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

PropertyValueSecurity5.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageBalance1 = ({ control, required }) => {
  const [mortgageBalance1] = useState(() => {
    return getFieldValues("mortgageBalance1", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={mortgageBalance1}
      name={"mortgageBalance1"}
      control={control}
      label={"Mortgage Balance"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
      popoverContent={
        "The total balance of any mortgages secured on the property that are not being cleared and will still be outstanding upon receipt of the bridging loan."
      }
    />
  );
};

MortgageBalance1.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageBalance2 = ({ control, required }) => {
  const [mortgageBalance2] = useState(() => {
    return getFieldValues("mortgageBalance2", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={mortgageBalance2}
      name={"mortgageBalance2"}
      control={control}
      label={"2nd Mortgage Balance"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

MortgageBalance2.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageBalance3 = ({ control, required }) => {
  const [mortgageBalance3] = useState(() => {
    return getFieldValues("mortgageBalance3", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={mortgageBalance3}
      name={"mortgageBalance3"}
      control={control}
      label={"3rd Mortgage Balance"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

MortgageBalance3.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageBalance4 = ({ control, required }) => {
  const [mortgageBalance4] = useState(() => {
    return getFieldValues("mortgageBalance4", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={mortgageBalance4}
      name={"mortgageBalance4"}
      control={control}
      label={"4th Mortgage Balance"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

MortgageBalance4.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageBalance5 = ({ control, required }) => {
  const [mortgageBalance5] = useState(() => {
    return getFieldValues("mortgageBalance5", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={mortgageBalance5}
      name={"mortgageBalance5"}
      control={control}
      label={"5th Mortgage Balance"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
    />
  );
};

MortgageBalance5.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const LoanTermText = ({ control, required }) => {
  const [loanTermText] = useState(() => {
    return getFieldValues("loanTermText", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={loanTermText}
      name={"loanTermText"}
      control={control}
      label={"Term"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={2}
      endAdornment={"years"}
      limitToIntegersBetween1And40={true}
      popoverContent={
        "The maximum term for a mortgage is 40 years"
      }
    />
  );
};

LoanTermText.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const LoanTermText2 = ({ control, required }) => {
  const [loanTermText] = useState(() => {
    return getFieldValues("loanTermText2", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={loanTermText}
      name={"loanTermText2"}
      control={control}
      label={"Term"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={2}
      endAdornment={"years"}
      limitToIntegersBetween1And40={true}
    />
  );
};

LoanTermText2.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const InterestRate = ({ control, required }) => {
  const [interestRate] = useState(() => {
    return getFieldValues("interestRate", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={interestRate}
      name={"interestRate"}
      control={control}
      label={"Interest Rate"}
      required={required}
      pattern={/^\d+\.?\d{0,2}$/}
      maxLength={3}
      endAdornment={"%"}
      limitToTwoDecimalPlaces={true}
    />
  );
};

InterestRate.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const InterestRate2 = ({ control, required }) => {
  const [interestRate2] = useState(() => {
    return getFieldValues("interestRate", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={interestRate2}
      name={"interestRate2"}
      control={control}
      label={"Interest Rate"}
      required={required}
      pattern={/^\d+\.?\d{0,2}$/}
      maxLength={3}
      endAdornment={"%"}
      limitToTwoDecimalPlaces={true}
    />
  );
};

InterestRate2.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageType = ({ control, required }) => {
  const options = [
    { value: "repayment", label: "Repayment" },
    { value: "interestonly", label: "Interest Only" },
  ];

  return (
    <SelectElement
      defaultValue={'repayment'}
      name={"mortgageType"}
      control={control}
      label={"Mortgage Type"}
      required={required}
      options={options}
      popoverContent={
        "Repayment: The monthly payment is made up of capital and interest. Interest Only: The monthly payment is made up of interest only. The capital is repaid at the end of the term."
      }
    />
  );
};

MortgageType.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageType2 = ({ control, required }) => {
  const options = [
    { value: "repayment", label: "Repayment" },
    { value: "interestonly", label: "Interest Only" },
  ];

  return (
    <SelectElement
      defaultValue={'repayment'}
      name={"mortgageType2"}
      control={control}
      label={"Mortgage Type"}
      required={required}
      options={options}
      popoverContent={
        "Repayment: The monthly payment is made up of capital and interest. Interest Only: The monthly payment is made up of interest only. The capital is repaid at the end of the term."
      }
    />
  );
};

MortgageType2.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageAmount = ({ control, required }) => {
  const [mortgageAmount] = useState(() => {
    return getFieldValues("mortgageAmount", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={mortgageAmount}
      name={"mortgageAmount"}
      control={control}
      label={"Mortgage Amount"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
      formatThousands={true}
    />
  );
};

MortgageAmount.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const MortgageAmount2 = ({ control, required }) => {
  const [mortgageAmount2] = useState(() => {
    return getFieldValues("mortgageAmount2", buyProperty);
  });
  return (
    <TextFieldElement
      defaultValue={mortgageAmount2}
      name={"mortgageAmount2"}
      control={control}
      label={"Mortgage Amount"}
      required={required}
      pattern={/^(?=.*\d)[\d]+$/}
      maxLength={6}
      startAdornment={"£"}
      formatThousands={true}
    />
  );
};

MortgageAmount2.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};


export const RightToLightSelectMenu = ({ control, required }) => {
  const options = [
    { value: "959170", label: "Right To Light Report" },
    { value: "411", label: "Daylight and Sunlight Assessment" },
  ];

  return (
    <SelectElement
      defaultValue={"959170"}
      name={"rightToLightSelectMenu"}
      control={control}
      label={"Service Required"}
      required={required}
      options={options}
      popoverContent={"<span style='font-weight:500;'>Right To Light Report</span><br/>Used to prove loss of light due to a neighbour’s development or tree. Also needs to be considered by developers and those thinking of extending their home.<br/><br/><span style='font-weight:500;'>Daylight and Sunlight Assessment</span><br/>Sometimes required by planning authorities as part of a planning application. Neighbours affected by building works may need advice."}
      />
  );
};

RightToLightSelectMenu.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};

export const AsbestosSurveySelectMenu = ({ control, required }) => {
  const options = [
    { value: "339", label: "Residential" },
    { value: "810826", label: "Commercial" },
  ];

  return (
    <SelectElement
      defaultValue={"339"}
      name={"asbestosSurveySelectMenu"}
      control={control}
      label={"Type of Property"}
      required={required}
      options={options}
      />
  );
};

AsbestosSurveySelectMenu.propTypes = {
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
};