import React, { useContext } from "react";
import PropTypes from "prop-types";

// Context
import { DesktopContext } from "../../App";

// MUI
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function SubmitButton({
  buttonText,
  handleSubmit,
  onSubmit,
  buttonFooterText,
  isSubmitting,
  disabled,
}) {
    //eslint-disable-next-line
  const desktop = useContext(DesktopContext);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Button
        disabled={isSubmitting || disabled}
        size="medium"
        sx={{
          width: desktop ? "340px" : "100%",
          marginTop: "8px",
          fontSize: "1.1rem",
          fontWeight: 600,
        }}
        variant="contained"
        onClick={handleSubmit(onSubmit)}
        disableElevation
      >
        {buttonText}
      </Button>
  
      <Typography
        sx={{
          lineHeight: "1.5 !important",
          fontSize: "0.8rem !important",
          textAlign: "center",
        }}
        style={{ marginTop: "8px" }}
      >
        {buttonFooterText}
      </Typography>
    </Box>
  );
}

SubmitButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonFooterText: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default SubmitButton;
